import React, {useEffect, useState} from 'react';
import {Button, Col, FormControl, Row, Table} from "react-bootstrap";
import Avatar from "../../../../components/Avatar";

import './MembersTable.scss';
import {ADD_MEMBER, buildUrl, MEMBER_PROFILE, PLATFORM_HOME} from "../../../../services/PageUtils";
import {useNavigate} from "react-router-dom";
import {AddCircle} from "@material-ui/icons";
import {darkerHandicaddieGreen} from "../../../../services/Utils";
import {Member} from "../../../../services/member/MemberService";
import {handicaddieSelector} from "../../../../services/store/asyncThunk";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../services/store/store";
import {searchForMembers} from "../../../../services/store/reducers/clubs/asyncActions";
import ClubSelectorDropdown from "../../../../components/dropdown/clubselector/ClubSelectorDropdown";

interface Props {
}

const MembersTable = ({}: Props) => {

    const navigate = useNavigate();

    const [searchTerm, setSearchTerm] = useState<string>("")
    const [tableMembers, setTableMembers] = useState<Member[]>([])

    const { club, members } = handicaddieSelector(state => state.clubs.selectedClub)
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (club && !club.hasMembers) {
            navigate(buildUrl(PLATFORM_HOME))
        } else if (club && club.hasMembers) {
            dispatch(searchForMembers(searchTerm))
        }
    }, [club]);

    useEffect(() => {
        if (searchTerm === null) {
            setTableMembers(members)
            return;
        }

        setTableMembers(
            members.filter(m => m.user.displayName.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }, [members]);

    const updateSearch = (value: string) => {
        setSearchTerm(value)
        dispatch(searchForMembers(value))
    }

    const navigateToAddMember = () => {
        navigate(buildUrl(ADD_MEMBER))
    }

    const navigateToMemberProfile = (member: Member) => {
        navigate(buildUrl(MEMBER_PROFILE
            .replace(":clubId", member.clubId)
            .replace(":memberId", member.id)));
    }

    return (
        <>
            <Row>
                <Col xs={12} md={6}>
                    <h3 className="pageSubHeading">Members</h3>
                </Col>
                <Col xs={12} md={6}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <ClubSelectorDropdown classNames={"m-r-10"} />
                        <Button
                            style={{ marginRight: '0.5em', backgroundColor: darkerHandicaddieGreen }}
                            onClick={() => navigateToAddMember()}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <AddCircle style={{ marginRight: '0.5em' }}/> New
                            </div>
                        </Button>
                        <FormControl type="text" placeholder="Search" onChange={(e) => updateSearch(e.target.value)}/>
                    </div>
                </Col>
            </Row>

            <Row>
                <Table borderless>
                    <thead className={"membersTable_headingContainer"}>
                        <tr>
                            <td>Name</td>
                            <td>Club</td>
                            <td>Profile</td>
                        </tr>
                    </thead>
                    <tbody className={"membersTable_bodyContainer"}>
                        {tableMembers && tableMembers.map(member => {
                            return <tr key={member.id}>
                                <td>
                                    <Avatar size={"xs"} user={member.user.id}/>
                                    {member.user.displayName}
                                </td>
                                <td>
                                    {member.clubName}
                                </td>
                                <td>
                                    <span
                                        className={"membersTable_viewProfileBtn"}
                                        onClick={() => navigateToMemberProfile(member)}>
                                        VIEW
                                    </span>

                                    {/*<DeleteOutline*/}
                                    {/*    className={"membersTable_deleteBtn"}*/}
                                    {/*    onClick={() => showDeleteAlert(member.caddieId, member.displayName)} />*/}
                                </td>
                            </tr>
                        })}
                        {tableMembers && tableMembers.length === 0 && <tr>
                            <td className={"membersTable_colOne"}>No members...</td>
                            <td/>
                            <td/>
                        </tr>}
                    </tbody>
                </Table>
            </Row>
        </>
    )
}

export default MembersTable;