import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {logErrorFromApi} from "../services/Utils";
import {Member, MemberService} from "../services/member/MemberService";
import MemberProfileHeader from "../parts/caddiemaster/members/profile/header/MemberProfileHeader";
import {Alert, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {MemberProfileContent} from "../parts/caddiemaster/members/profile/content/MemberProfileContent";
import {buildUrl, MEMBERS} from "../services/PageUtils";
import {Formik} from "formik";
import {useStore} from "../services/state/useStore";
import {useApi} from "../services/useApi";
import * as yup from "yup";
import {handicaddieSelector} from "../services/store/asyncThunk";
import {toast} from "react-toastify";

interface Props {
}

const MemberProfile = ({}: Props) => {

    const navigate = useNavigate();

    const { selectedClub } = handicaddieSelector(state => state.clubs)

    const [creating, setCreating] = useState(false);

    const schema = yup.object().shape({
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
        email: yup.string().required("Email is required").email()
    });

    useEffect(() => setCreating(false), []);

    const submit = async (values: any) => {
        if (creating) {
            return;
        }

        if (!selectedClub || !selectedClub.club) {
            toast.error("Unable to detect current club. Please refresh and try again.")
            return;
        }
        setCreating(true);
        try {
            let m = await MemberService().createMember(selectedClub.club.id, values.firstName, values.lastName, values.email)
            if (m) {
                toast.success('Member created successfully.')
                navigate(buildUrl(MEMBERS));
            }
        } finally {
            setCreating(false);
        }
    }

    return (
        <Container style={{ marginTop: '16px' }}>
            <Formik
                validationSchema={schema}
                onSubmit={submit}
                initialValues={{
                    firstName: "",
                    lastName: "",
                    email: ""
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      values,
                      touched,
                      isValid,
                      errors,
                  }) => (
                    <Form
                        style={{
                            marginTop: "10px",
                            padding: "2rem",
                        }}
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <Row>
                            <h3 className="pageSubHeading">Add New Member</h3>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group controlId="detailsForm.FirstName">
                                    <Form.Label column={'lg'}>
                                        First Name
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        className="form-input"
                                        type="text"
                                        name="firstName"
                                        placeholder="First Name"
                                        onBlur={handleBlur}
                                        value={
                                            values.firstName
                                        }
                                        onChange={handleChange}
                                    />
                                    {touched.firstName && errors.firstName && (
                                        <Alert variant={"danger"}>
                                            {errors.firstName}
                                        </Alert>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="detailsForm.LastName">
                                    <Form.Label column={'lg'}>
                                        Last Name
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        className="form-input"
                                        type="text"
                                        name="lastName"
                                        placeholder="Last Name"
                                        onBlur={handleBlur}
                                        value={
                                            values.lastName
                                        }
                                        onChange={handleChange}
                                    />
                                    {touched.lastName && errors.lastName && (
                                        <Alert variant={"danger"}>
                                            {errors.lastName}
                                        </Alert>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="detailsForm.Email">
                                    <Form.Label column={'lg'}>
                                        Email
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        className="form-input"
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        onBlur={handleBlur}
                                        value={
                                            values.email
                                        }
                                        onChange={handleChange}
                                    />
                                    {touched.email && errors.email && (
                                        <Alert variant={"danger"}>
                                            {errors.email}
                                        </Alert>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                {
                                    creating &&
                                    <Spinner animation={"border"}
                                             variant="primary"
                                             style={{marginRight: '1em'}} />
                                }
                                <Button type="submit" disabled={creating}>Add Member</Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </Container>
    )

}

export default MemberProfile;