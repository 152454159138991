import React, {ReactElement} from 'react';
import SlotCountIndicator from "../components/SlotCountIndicator";
import {BookingSlot, ClubBooking, Course} from "../../../../services/ApiDomain";
import BookingCaddieBadge from "../../../../components/scheduler/caddie_badge/BookingCaddieBadge";
import {DeleteOutlined, EditOutlined} from "@material-ui/icons";
import {DateSchedulerService} from "../../../../services/scheduler/DateSchedulerService";
import {Badge} from "react-bootstrap";
import {handicaddieSelector} from "../../../../services/store/asyncThunk";
import {addBooking} from "../../../../services/store/reducers/scheduler/asyncActions";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../services/store/store";
import {handleSelectedTeeTime} from "../../../../services/store/reducers/scheduler/slice";

interface Props {
    displayCaddieSelector: (bookingSlot: BookingSlot, date: string, booking: ClubBooking) => void
    displayEditBookingModal: () => void
    displayDeleteBookingModal: (id: string) => void
}

const MobileDateScheduler = ({
                                 displayCaddieSelector,
                                 displayEditBookingModal,
                                 displayDeleteBookingModal,
                             }: Props) => {

    const { buildTimeSheet } = DateSchedulerService();

    const {
        timesheet,
        hideEmptyTeeTimes,
        courseFilter
    } = handicaddieSelector(state => state.scheduler)
    const {
        selectedClub,
    } = handicaddieSelector(state => state.clubs)

    const dispatch = useDispatch<AppDispatch>()

    const onSlotClick = (teeTime: string, slots: number, courseId?: string): void => {
        let c: Course|undefined = undefined
        if (courseId) {
            let matchingCourses = selectedClub.courses.filter(course => course.id === courseId);
            if (matchingCourses.length === 1) {
                c = matchingCourses[0]
            }
        }

        dispatch(handleSelectedTeeTime(
            {
                teeTime: teeTime,
                isSqueezedOrNoTeeTimeBooking: false,
                course: c
            }
        ))

        dispatch(addBooking({
            teeTime: teeTime,
            bookingSlots: Array(slots).fill({
                bookingId: "",
                status: "OPEN"
            }),
            courseId: courseId,
        }))
    }

    const editBooking = (teeTime: string,
                         booking: ClubBooking,
                         course?: Course,
                         teeTimesInPool?: number) => {
        dispatch(handleSelectedTeeTime(
            {
                booking: booking,
                teeTime: teeTime,
                teeTimesInPool: teeTimesInPool,
                isSqueezedOrNoTeeTimeBooking: false,
                course: course
            }
        ))
        displayEditBookingModal()
    }

    const buildRow = (teeTime: string,
                      rowBackgroundColour: string,
                      booking?: ClubBooking,
                      c?: Course,
                      endTeeTime?: string,
                      teeTimesInPool?: number) => {
        if (booking) {
            return <div
                key={teeTime + c?.id}
                style={{
                    backgroundColor: rowBackgroundColour,
                    padding: '1em',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.125)'
                }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    {teeTime}  {endTeeTime ? `- ${endTeeTime}` : ''}
                    {c && <Badge
                        style={{
                            backgroundColor: c.colourCode,
                            color: "white",
                            height: "fit-content"
                        }}>{c.name.length > 10 ? c.name.substr(0, 10) + "..." : c.name}</Badge>}
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div className='scheduler_actionBtn'
                             style={{marginRight: '0.25em'}}
                             onClick={() => editBooking(teeTime, booking, c, teeTimesInPool)}>
                            <EditOutlined style={{color: 'white', verticalAlign: 'text-top'}}/>
                        </div>
                        <div className='scheduler_actionBtn'
                             style={{marginRight: '0.25em'}}
                             onClick={() => displayDeleteBookingModal(booking.bookingId)}>
                            <DeleteOutlined style={{color: 'white', verticalAlign: 'text-top'}}/>
                        </div>
                    </div>
                </div>
                <div>
                    <h3>{booking.title}</h3>

                    {booking.bookingSlots
                        .map((bookingSlot) => {
                            return <BookingCaddieBadge
                                id={bookingSlot?.caddieId || ''}
                                name={bookingSlot?.displayName || ''}
                                date={new Date(booking.teeTime).toISOString()}
                                status={bookingSlot?.status || ''}
                                onClick={(id: string, date: string) => displayCaddieSelector(bookingSlot, date, booking)}
                            />
                        })}
                </div>
                {booking.caddieMasterNotes && <div style={{marginTop: '0.5em'}}>
                    <span><b>Caddie Master Notes</b></span>
                    <br/>
                    <span>{booking.caddieMasterNotes}</span>
                </div>}
            </div>
        }

        return <div
            key={teeTime + c?.id}
            style={{
                backgroundColor: rowBackgroundColour,
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                padding: '1em 1em',
                justifyContent: 'space-between',
                borderBottom: '1px solid rgba(0, 0, 0, 0.125)'
            }}
            // TODO :: THERE WAS AN ON ROW CLICK HERE - WHAT NOW?
        >
            <div>{teeTime}</div>
            {c && <Badge
                style={{
                    backgroundColor: c.colourCode,
                    color: "white",
                    height: "fit-content"
                }}>{c.name.length > 10 ? c.name.substr(0, 10) + "..." : c.name}</Badge>}
            <div>
                <SlotCountIndicator onClick={(count) => onSlotClick(teeTime, count, c?.id)} count={0} />
            </div>
        </div>
    }

    const courseRowBuilder = (
        c: Course,
        teeTime: string,
        displayTeeTime: boolean,
        rowBackgroundColour: string,
        booking?: ClubBooking,
        teeTimesInPool?: number,
        endTeeTime?: string): ReactElement => {
        // have to display tee time, looks better
        return buildRow(
            teeTime,
            // true,
            rowBackgroundColour,
            booking,
            c,
            endTeeTime,
            teeTimesInPool)
    }

    const noCourseRowBuilder = (
        teeTime: string,
        rowBackgroundColour: string,
        booking?: ClubBooking,
        teeTimesInPool?: number,
        endTeeTime?: string): ReactElement => {
        return buildRow(
            teeTime,
            // true,
            rowBackgroundColour,
            booking,
            undefined,
            endTeeTime,
            teeTimesInPool)
    }

    return (
        <div style={{marginTop: '1em', width: '100%'}}>
            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                {buildTimeSheet(courseRowBuilder, noCourseRowBuilder, timesheet, courseFilter?.id, hideEmptyTeeTimes, selectedClub.courses)}
            </div>
        </div>
    )
}

export default MobileDateScheduler;