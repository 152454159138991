export interface ApiError {
    code: string
    message: string
}

export type PagedApiResponse<T> = {
    resource: T
}

export interface Session {
    idToken: string
}

export interface User {
    id: string
    firstName: string
    lastName: string
    displayName: string
    email: string
    phoneNumber: string
    bio: string
}

export interface UserDetails {
    email: string
    firstName: string
    lastName: string
    phoneNumber: string
    bio: string
}

export interface UserNotificationPreference {
    id: string
    userId: string
    notification: string
    source: string
}

export interface CaddieMaster {
    id: string
    clubId: string
    clubName: string
    approved: boolean
}

export interface TourMaster {
    id: string
    tourId: string
    tourName: string
    approved: boolean
}

export interface UserBooking {
    bookingId: string
    caddieId: string
    clubId: string
    clubName: string
    teeTime: string
}

export interface MembershipDomain {
    name: string
    status: string
    type: string
    typeId?: string
    purchaseState?: string
}

export interface FeedItem {
    timestamp: string
    membershipName?: string
    type: string
    resource: CaddieAvailability | Booking | ClubBooking
}

export interface CaddieAvailability {
    caddie: string
    availabilities: string[]
}

export interface CaddieAvailabilityFeedItem {
    timestamp: string
    membershipName?: string
    type: string
    resource: CaddieAvailability
}

export interface CaddieBookingFeedItem {
    timestamp: string
    membershipName?: string
    type: string
    resource: Booking
}

export interface ClubBookingFeedItem {
    timestamp: string
    membershipName?: string
    type: string
    resource: ClubBooking
}

export interface UserCaddie {
    caddieId: string
    approved: boolean

    clubId: string
    clubName: string

    userId: string
    displayName: string
    phoneNumber: string
}

export interface ClubCaddie {
    caddieId: string;
    userId: string;
    firstName: string;
    displayName: string;
    approved: boolean;
    phoneNumber?: string;
    email?: string;
}

export interface CaddieClubAvailabilityWindowResource {
    from: string
    to: string
}

export interface ClubCaddieAvailabilityResource {
    id?: string
    doubleBag?: boolean
    date: string
    club?: {
        id: string
        name?: string
        verified?: boolean
    }
    bookings?: Booking[]
    noSmartphoneCaddie?: boolean
    windows?: CaddieClubAvailabilityWindowResource[]
}

export enum AvailabilityStatus {
    AVAILABLE = "AVAILABLE",
    UNAVAILABLE = "UNAVAILABLE",
    INSUFFICIENT_BALANCE = "INSUFFICIENT_BALANCE",
    MAX_BOOKINGS = "MAX_BOOKINGS",
    UNKNOWN = "UNKNOWN",
}

export interface UserAvailability {
    user: User
    caddieId?: string
    noSmartphoneCaddie?: boolean
    historicBookingsCount: number
    available: boolean
    availabilityStatus: AvailabilityStatus
    availabilities: ClubCaddieAvailabilityResource[]
}

export interface CaddieResource {
    id: string
    userId: string
    clubId: string
    approved: boolean
}

export interface TourCaddieResource {
    id: string
    userId: string
    tourId: string
    approved: boolean
}

export interface Club {
    id: string
    name: string
    paceOfPlayMinutes: number
    timesheetInterval: number
    timesheetStartTime: string
    timesheetEndTime: string
    hasTimesheet: boolean
    hasPlayers: boolean
    hasMembers: boolean
}

export interface TimesheetSettingsResource {
    paceOfPlayMinutes: number
    timesheetInterval: number
    timesheetStartTime: string
    timesheetEndTime: string
}

export interface Course {
    id: string
    name: string
    colourCode: string
    paceOfPlayMinutes: number
    timesheetInterval: number
    timesheetStartTime: string
    timesheetEndTime: string
    address?: Address
}

export interface TeeTimeResource {
    teeTime: string
    endTeeTime?: string
    teeTimesInPool?: number
    courseId?: string
    booking?: ClubBooking
}

export interface TimesheetResource {
    teeTimes: TeeTimeResource[]
}

export interface TaskResource {
    bookingId: string,
    caddieId: string,
    caddieName: string,
    teeTime: string,
    status: string,
    numberOfSlots: number
}

export interface TaskRequestResource {
    bookingId: string,
    caddieId: string,
    status: string,
    numberOfSlots: number,
    action: string
}

export interface AnalyticsResource {
    type: string,
    metric: string,
    payload?: any
}

export interface StatisticsAnalyticsResource {
    payload: StatisticsAnalyticsPayload
}

export interface StatisticsAnalyticsPayload {
    name: string
    value: string
    delta?: string
    deltaType?: string
}

export interface MostCaddiesRoundTableRow {
    userId: string;
    firstName: string;
    lastName: string;
    numberOfRounds: number;
}

export interface ClubBooking {
    bookingId: string,
    clubId: string,
    courseId: string,
    title: string,
    teeTime: string,
    endTeeTime: string,
    caddieMasterNotes: string,
    caddieNotes: string,
    bookingSlots: ClubBookingSlot[],
}

export interface ClubBookingSlot {
    id?: string,
    bookingId: string,
    isMemberRequest: boolean,
    slotTypeId?: string,
    caddieId?: string,
    userId?: string,
    status: string,
    rejectReasonDescription?: string
    displayName?: string
    noSmartphone?: boolean
    slotTypeTitle?: string
    player?: BookingSlotPlayer
}

export interface BookingSlot {
    id?: string,
    bookingId: string,
    slotTypeId?: string,
    caddieId?: string,
    status: string,
    isMemberRequest: boolean,
    player?: BookingSlotPlayer
}

export interface BookingSlotPlayer {
    id?: string
    type: string
    name: string
}

export interface Booking {
    id: string
    courseId: string
    teeTime: string
    endTeeTime: string
    title: string
    caddieMasterNotes: string
    caddieNotes: string
}

// export interface Caddie {
//     id: number;
//     displayName: string;
//     image?: string;
//     selected?: boolean;
//     availableFrom?: string;
//     availableTo?: string;
//     favPlayer?: string;
//     phoneNumber?: string;
//     home?: string;
//     schedule?: CaddieAvailability[]
// }

export interface Tour {
    id: string
    name: string
}

export interface TourCaddie {
    caddieId: string
    userId: string

    approved: boolean
    displayName: string
    currentMember: boolean
}

export interface TourCaddieApplicationAnswer {
    index: number
    question: string
    answer: string
}

export interface TourCaddieApplicationSummary {
    id: string
    tourId: string
    userId: string
    displayName: string
    contactConsent: boolean
}

export interface PagedResource {
    page: number
    pageCount: number
    size: number
    totalCount: number
}

export interface SlotType {
    id?: string,
    clubId: string,
    emoji?: string,
    title: string
}

export interface Address {
    lineOne?: string,
    lineTwo?: string,
    lineThree?: string,
    lineFour?: string,
    lineFive?: string,
}

//
// export interface CaddieApprovalSuccess {
//     success: string
// }
//
// export interface CaddieScheduleDate {
//     booked: boolean;
//     date: string;
//     id: number;
// }
//
// export interface CaddieBooking {
//     id: number;
//     clubId: string;
//     allowCancel: boolean,
//     date: string;
//     group: boolean;
//     notes: string;
//     player: string;
//     time: string;
//     type: string;
// }
//
// export interface Club {
//     id: string;
//     name: string;
//     verified: boolean
// }

// export interface Slot {
//     caddie: SlotCaddie
//     clubPlayer: boolean
//     lead: boolean
//     player: number
//     playerEmail: string
//     playerName: string
//     slot_id: number
// }
//
// export interface SlotCaddie {
//     avatar: string
//     caddieId: number
//     displayName: string
//     userId: number
// }
