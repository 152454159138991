import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Profile} from "../parts/profile/Profile";
import {logErrorFromApi} from "../services/Utils";
import {useApi} from "../services/useApi";
import {Club, ClubCaddie} from "../services/ApiDomain";

interface Props {
}

const CaddieProfile = ({ }: Props) => {

    const [caddie, setCaddie] = useState<ClubCaddie>();
    const [club, setClub] = useState<Club>();

    const navigate = useNavigate();
    const { clubId, caddieId } = useParams();
    const { getAvatar, getCaddie, getClub } = useApi();

    useEffect(() => {
        if (!caddieId || !clubId) {
            return;
        }

        loadCaddie(clubId, caddieId)
            .catch((err) => {
                logErrorFromApi(err);
                navigate(-1);
            });

        loadClub(clubId)
            .catch((err) => logErrorFromApi(err));
    }, [caddieId, clubId]);

    const loadClub = async (clubId: string) => {
        setClub(await getClub(clubId));
    }

    const loadCaddie = async (clubId: string, caddieId: string) => {
        let clubCaddie = await getCaddie(clubId, caddieId);

        setCaddie(clubCaddie);
    }

    return (
        <>
            {caddie && club && <Profile
                userId={caddie.userId}
                isCurrentUser={false}
                displayName={caddie.displayName}
                email={caddie.email}
                phone={caddie.phoneNumber}
                isCaddieMasterForUser={true}
                caddieId={caddie.caddieId}
                clubId={club.id}
                clubName={club.name}
            />}
            {!caddie && <p>Loading... </p>}
        </>
    )

}

export default CaddieProfile;