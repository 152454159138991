import React, {useEffect, useState} from 'react';
import {
    ColumnFiltersState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    useReactTable,
} from '@tanstack/react-table';
import moment from "moment";
import {toast} from "react-toastify";
import {Button, Dropdown, Form, Spinner} from 'react-bootstrap';
import {ArrowRightAlt, CheckCircleOutlined, ExpandLess, ExpandMore, InfoOutlined, Tune} from "@material-ui/icons";
import {useApi} from "../../../services/useApi";
import {darkerHandicaddieGreen, logErrorFromApi} from "../../../services/Utils";
import {TaskRequestResource, TaskResource} from "../../../services/ApiDomain";
import './Tasks.scss';
import {useSelector} from "react-redux";
import {RootState} from "../../../services/store/store";

const columnHelper = createColumnHelper<TaskResource>()

const columns = [
  columnHelper.accessor('caddieName', {
    header: () => <span>Caddie Name</span>,
  }),
  columnHelper.accessor('teeTime', {
    header: () => <span>Date of Booking</span>,
  }),
  columnHelper.accessor('status', {
    header: '',
  }),
  columnHelper.accessor(row => row.status, {
    id: 'actions',
    header: () => <span>Actions</span>,
  }),
]

interface Props { }

export const Tasks = ({ }: Props) => {

  const [loading, setLoading] = useState(false);
  const [publishCount, setPublishCount] = useState(0);
  const [expandTasks, setExpandTasks] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [tasks, setTasks] = React.useState<TaskResource[]>(() => []);
  const [taskRequest, setTaskRequest] = useState<TaskRequestResource[]>(() => []);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [taskRequestResource, setTaskRequestResource] = useState<TaskRequestResource[]>(() => []);
  
  const { getTasks, updateTasks } = useApi();
  const { club } = useSelector((state: RootState) => state.clubs.selectedClub);
  const rerender = React.useReducer(() => ({}), {})[1]

  useEffect(() => {
    if (!club) {
      return;
    }

    loadTimeSheet(club.id);
  }, [club])

  const loadTimeSheet = async (clubId: string | undefined) => {
    if (!clubId) {
      return;
    }
    const taskResource = await getTasks(clubId);
    setTasks(taskResource);
    setTaskRequest([]);
    setTaskRequestResource([]);
    setPublishCount(0);
    rerender();
  }

  const table = useReactTable({
    data: tasks,
    columns,
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
  })

  const getStatusText = (status: string) => {
    switch (status) {
      case 'DRAFT':
        return 'Draft';
      case 'PENDING':
        return 'Pending';
      case 'REJECTED':
        return 'Rejected';
      case 'OPEN_SLOT':
        return 'Open Slot';
      case 'PUBLIC_SLOT':
        return 'Public Slot';
      default:
        return '';
    }
  };

  const handleTaskAction = (rowId: any, row: any, status: any) => {
    let existingTaskRequest = [...taskRequest];
    const existingRecord = existingTaskRequest[rowId];
    if (existingRecord) {
      if (!(existingRecord.bookingId === row.bookingId && existingRecord.caddieId === row.caddieId &&
        existingRecord.status === row.status && existingRecord.numberOfSlots === row.numberOfSlots)) {
        toast.error('Error: please try reloading the page and trying again.');
        return;
      }
    }
    existingTaskRequest[rowId] = {
      bookingId: row.bookingId,
      caddieId: row.caddieId,
      status: row.status,
      action: status,
      numberOfSlots: row.numberOfSlots
    };
    setTaskRequest(existingTaskRequest);
    const taskRequestResource = existingTaskRequest.filter(item => item && item.action !== "NO_ACTION");
    setTaskRequestResource(taskRequestResource);
    setPublishCount(taskRequestResource?.length);
  }

  const handleStatusFilter = (column: any, status: string) => {
    column.setFilterValue(status);
    setSelectedStatus(status);
  }

  const publishTasks = () => {
    if (!club) {
      return;
    }
    setLoading(true);

    updateTasks(club.id, taskRequestResource)
      .then(() => {
        toast.success(`${publishCount > 1 ? "Tasks" : "Task"} published successfully.`);
        loadTimeSheet(club.id);
        setLoading(false);
      })
      .catch((error) => {
        logErrorFromApi(error);
        setLoading(false);
      })
  }

  const getColumnData = (columnId: any, row: any, cell: any) => {
    switch (columnId) {
      case 'teeTime':
        let teeTime = moment(cell.getValue() as string);
        if (teeTime.hour() === 2 && teeTime.minute() === 2) {
          return (teeTime.format("dddd Do, ")) + "no tee time.";
        }
        return (teeTime.format("dddd Do, HH:mm"));
      case 'status':
        return (
          <span>
            {getStatusText(cell.getValue())}
            <ArrowRightAlt style={{ marginLeft: 10, float: 'right' }} />
          </span>
        );
      case 'actions':
        return (
          <span>
            <Form.Control as="select"
              style={{ border: 'none', padding: 0, height: 32, boxShadow: 'none' }}
              disabled={row?.original?.status === "PUBLIC_SLOT"}
              onChange={(e) => handleTaskAction(row?.id, row?.original, e.target.value)}
              value={taskRequest[row?.id as any]?.action || "NO_ACTION"}>
              <option value="NO_ACTION">No Action</option>
              {row?.original?.status === "DRAFT" && <option value="PUBLISH">Publish</option>}
              <option value="OPEN_TO_PUBLIC">Open to public</option>
            </Form.Control>
          </span>
        );
      default:
        return (flexRender(cell.column?.columnDef?.cell, cell.getContext()));
    }
  };

  return (
    <div>
      <div className="tasks-title">
          {tasks.length > 0 ?
              <>
                <span>
                  <InfoOutlined style={{
                    color: 'inherit',
                    verticalAlign: 'bottom',
                    fontSize: '1.3rem',
                    marginRight: '0.5em',
                    marginBottom: '0.1em'
                  }}/>
                  You have bookings that need your attention
                </span>
                <span className='expand-task-icon'>
                  {expandTasks ?
                      <ExpandLess style={{color: 'white'}} onClick={() => setExpandTasks(false)}/> :
                      <ExpandMore style={{color: 'white'}} onClick={() => setExpandTasks(true)}/>}
                </span>
              </> :
              <>
                <span>
                <CheckCircleOutlined style={{
                  color: 'inherit',
                  verticalAlign: 'bottom',
                  fontSize: '1.3rem',
                  marginRight: '0.5em',
                  marginBottom: '0.1em'
                }}/>
                It all looks good!
                </span>
              </>}
      </div>
      {expandTasks && <>
        <div className="tasks-table">
          <table className='tasks-table-content'>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header,header.getContext())}
                      {header.column.getCanFilter() && header.column.id === 'status' ? (
                        <div style={{ border: '2px solid #dddee1' }}>
                          <Dropdown>
                            <Dropdown.Toggle variant="success" id="tasks-status-dropdown" style={{ backgroundColor: '#ffffff' }}>
                              <Tune style={{ marginLeft: 5, marginRight: 5, marginBottom: 1 }} /> Status
                            </Dropdown.Toggle>

                            <Dropdown.Menu popperConfig={{ strategy: "fixed" }}>
                              <Dropdown.Item eventKey="1" active={selectedStatus === ""} onClick={() => handleStatusFilter(header.column, "")}>All</Dropdown.Item>
                              <Dropdown.Item eventKey="2" active={selectedStatus === "DRAFT"} onClick={() => handleStatusFilter(header.column, "DRAFT")}>Draft</Dropdown.Item>
                              <Dropdown.Item eventKey="2" active={selectedStatus === "PENDING"} onClick={() => handleStatusFilter(header.column, "PENDING")}>Pending</Dropdown.Item>
                              <Dropdown.Item eventKey="3" active={selectedStatus === "REJECTED"} onClick={() => handleStatusFilter(header.column, "REJECTED")}>Rejected</Dropdown.Item>
                              <Dropdown.Item eventKey="4" active={selectedStatus === "OPEN_SLOT"} onClick={() => handleStatusFilter(header.column, "OPEN_SLOT")}>Open Slot</Dropdown.Item>
                              <Dropdown.Item eventKey="5" active={selectedStatus === "PUBLIC_SLOT"} onClick={() => handleStatusFilter(header.column, "PUBLIC_SLOT")}>Public Slot</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      ) : null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>
                      {getColumnData(cell?.column?.id, row, cell)}
                    </td>
                  ))}
                </tr>
              ))}
              {table.getRowModel().rows.length === 0 && <tr><td colSpan={4} style={{ textAlign: 'center' }}>No tasks found</td></tr>}
            </tbody>
          </table>
        </div>

        <div className="tasks-footer">
          <span style={{ marginRight: 20 }}>Would you like to publish it now?</span>
          <Button disabled={loading || (publishCount <= 0)}
                  style={{ backgroundColor: darkerHandicaddieGreen }}
                  className="font-weight-bold px-5"
                  onClick={publishTasks}>
            {`PUBLISH ACTIONS (${publishCount})`}
          </Button>
          {loading &&
            <Spinner animation={"border"} variant="primary" style={{ marginLeft: '1em' }} />}
        </div>
      </>}
    </div>
  )
}