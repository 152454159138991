import React from "react";
import ContactMethodsTable from "./contactmethods/ContactMethodsTable";
import {Member} from "../../../../../../services/member/MemberService";

interface Props {
    member: Member
}

export const MemberProfileInfo = ({member}: Props) => {

    const contactMethods = [];

    if (member.user.email) {
        contactMethods.push({
            "type": "Email",
            "value": member.user.email
        })
    }

    if (member.user.phoneNumber) {
        contactMethods.push({
            "type": "Phone Number",
            "value": member.user.phoneNumber
        })
    }

    return <>
        <ContactMethodsTable contactMethods={contactMethods} />
    </>
}