import './schedulerHeader.scss'

import React, {useEffect, useRef, useState} from 'react';
import SchedulerHeaderDate from "./components/SchedulerHeaderDate";
import {
    AddAlert,
    CalendarToday,
    ChevronLeft,
    ChevronRight,
    GroupOutlined,
    RefreshOutlined,
    VisibilityOffOutlined,
    VisibilityOutlined,
} from "@material-ui/icons";
import moment from "moment";

import {Overlay, Spinner, Tooltip} from "react-bootstrap";
import BusyDayModal from './BusyDayModal';
import ClubSelectorDropdown from "../../../components/dropdown/clubselector/ClubSelectorDropdown";
import {darkerHandicaddieGreen} from "../../../services/Utils";

import {handicaddieSelector} from "../../../services/store/asyncThunk";
import {dateWithoutTimezone} from "../../../services/store/reducers/scheduler/service";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../services/store/store";
import {
    handleDateChange,
    handleDrawerChange,
    handleToggleHideTeeTimes
} from "../../../services/store/reducers/scheduler/slice";
import {Drawer} from "../../../services/store/reducers/scheduler/domain";
import {reloadScheduler} from "../../../services/store/reducers/scheduler/asyncActions";

interface Props {
    showPublishScheduleModal: (x: boolean) => void
}

const SchedulerHeader = ({showPublishScheduleModal}: Props) => {

    const [dates, setDates] = useState<Date[]>([]);
    const [showBusyDayModal, setShowBusyDayModal] = useState(false);

    const [showSettingsLabel, setShowSettingsLabel] = useState(false);
    const settingsTarget = useRef(null);

    const [showAvailCaddiesLabel, setShowAvailCaddiesLabel] = useState(false);
    const availCaddiesTarget = useRef(null);

    const [showRefreshLabel, setShowRefreshLabel] = useState(false);
    const refreshLabelTarget = useRef(null);

    const [showTodayLabel, setShowCalendarLabel] = useState(false);
    const todayLabelTarget = useRef(null);

    const [showHideTimesLabel, setShowHideTimesLabel] = useState(false);
    const hideTimesLabelTarget = useRef(null);

    const [showBusyDayLabel, setShowBusyDayLabel] = useState(false);
    const busyDayTarget = useRef(null);

    const [showPublishLabel, setShowPublishLabel] = useState(false);
    const publishTarget = useRef(null);

    const dateInput = useRef<HTMLInputElement>(null);

    const [publishScheduleBtnLabel, setPublishScheduleBtnLabel] = useState<string>();

    const {
        loading,
        selectedDate,
        hideEmptyTeeTimes,
        hasDraftBookings,
    } = handicaddieSelector(state => state.scheduler)
    const {selectedClub} = handicaddieSelector(state => state.clubs)
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        calculateDatesFrom(new Date(selectedDate));
    }, [])

    useEffect(() => {
        if (hasDraftBookings) {
            setPublishScheduleBtnLabel('Publish the caddies in DRAFT.')
            return;
        }

        let now = moment()
        let date = moment(selectedDate)

        if (now.isSame(date, 'day')) {
            setPublishScheduleBtnLabel('Draft mode is disabled on the current date.')
            return;
        }

        setPublishScheduleBtnLabel('You have no caddies in DRAFT.')
    }, [selectedDate, hasDraftBookings]);

    function calculateDatesFrom(start: Date) {
        let d = []
        let cursor = new Date(start);
        let count = 14;
        if (window.innerWidth < 500) {
            count = 3;
        } else if (window.innerWidth < 750) {
            count = 5;
        } else if (window.innerWidth < 1000) {
            count = 7;
        }
        for (let i = 0; i < count; i++) {
            d.push(new Date(cursor))
            cursor.setDate(cursor.getDate() + 1)
        }

        setDates(d)
    }

    const addDates = (n: number) => {
        let d = dates[0]
        d.setDate(d.getDate() + n)
        calculateDatesFrom(d)
    }

    const onDateSelect = (dStr: string) => {
        let momentDate = moment(dStr, 'YYYY-MM-DD').startOf('day');
        let jsDate = dateWithoutTimezone(momentDate.toDate());
        dispatch(handleDateChange(jsDate))
    }

    const showCalendar = () => {
        if ('showPicker' in HTMLInputElement.prototype) {
            // @ts-ignore - I don't know why showPicker() isn't an allowed method for HTMLInputElement 🤷‍

            dateInput.current!.showPicker();
        }
    }

    const showModal = () => {
        setShowBusyDayModal(true);
    }

    const handleBusyDayModalClose = () => {
        setShowBusyDayModal(false);
    }

    return (
        <div style={{position: 'sticky', top: 70, display: 'block'}}>
            <div className="datesBar">
                <div className="flex">
                    {('showPicker' in HTMLInputElement.prototype) && <>
                        <div className="todayContainer"
                             onClick={showCalendar}
                             ref={todayLabelTarget}
                             onMouseEnter={() => setShowCalendarLabel(true)}
                             onMouseLeave={() => setShowCalendarLabel(false)}>
                            <CalendarToday width={5}/>
                            <p>{moment(selectedDate).format('ddd Do MMM')}</p>
                            <input
                                type="date"
                                style={{
                                    visibility: 'hidden',
                                    position: 'absolute'
                                }}
                                ref={dateInput}
                                value={new Date(selectedDate).toISOString().split("T")[0]}
                                min={new Date().toISOString().split("T")[0]}
                                onChange={e => onDateSelect(e.target.value)}
                            />
                        </div>
                    </>}

                    {!('showPicker' in HTMLInputElement.prototype) && <>
                        <div
                            className={"todayContainerInput"}
                            ref={todayLabelTarget}
                            onMouseEnter={() => setShowCalendarLabel(true)}
                            onMouseLeave={() => setShowCalendarLabel(false)}>
                            <CalendarToday width={5}/>
                            <input
                                type="date"
                                ref={dateInput}
                                value={new Date(selectedDate).toISOString().split("T")[0]}
                                min={new Date().toISOString().split("T")[0]}
                                onChange={e => onDateSelect(e.target.value)}
                            />
                        </div>
                    </>}

                    <Overlay target={todayLabelTarget.current} show={showTodayLabel} placement="top">
                        {(props) => <Tooltip id="overlay-example" {...props}>Click to view full calendar.</Tooltip>}
                    </Overlay>
                </div>
                <div style={{width: '75%'}}>
                    <p className="monthHeading">
                        {moment(dates[0]).format('MMMM YYYY')}
                    </p>
                    <div className="datesContainer">
                        <ChevronLeft width={20} onClick={() => addDates(-1)}/>
                        <div>
                            {dates.map((d) => {
                                    let selectedDateAsDate = new Date(selectedDate)
                                    return <SchedulerHeaderDate
                                        key={d.getDate()}
                                        date={d}
                                        highlighted={d.getDate() === selectedDateAsDate.getDate() &&
                                            d.getMonth() === selectedDateAsDate.getMonth()}
                                        onClick={(e) => onDateSelect(dateWithoutTimezone(e))}
                                    />
                                }
                            )}
                        </div>
                        <ChevronRight width={20} onClick={() => addDates(1)}/>
                    </div>
                </div>
            </div>
            <Overlay target={todayLabelTarget.current} show={showTodayLabel} placement="top">
                {(props) => <Tooltip id="overlay-example" {...props}>Click to view full calendar.</Tooltip>}
            </Overlay>
            <div className="d-flex align-items-center mt-4">
                <ClubSelectorDropdown/>
                <div className="scheduler_actionsBar mt-0">
                    {selectedClub.club?.hasTimesheet && <div className='scheduler_actionBtn'
                                                             onClick={() => dispatch(handleToggleHideTeeTimes())}
                                                             ref={hideTimesLabelTarget}
                                                             onMouseEnter={() => setShowHideTimesLabel(true)}
                                                             onMouseLeave={() => setShowHideTimesLabel(false)}>
                        {hideEmptyTeeTimes && <VisibilityOffOutlined style={{color: 'white'}}/>}
                        {!hideEmptyTeeTimes && <VisibilityOutlined style={{color: 'white'}}/>}
                        <Overlay target={hideTimesLabelTarget.current} show={showHideTimesLabel} placement="top">
                            {(props) => <Tooltip id="overlay-example" {...props}>Show/Hide Bookings with No
                                Caddies</Tooltip>}
                        </Overlay>
                    </div>}
                    <div className='scheduler_actionBtn'
                         onClick={() => dispatch(reloadScheduler())}
                         style={{marginRight: '0.25em'}}
                         ref={refreshLabelTarget}
                         onMouseEnter={() => setShowRefreshLabel(true)}
                         onMouseLeave={() => setShowRefreshLabel(false)}>
                        {loading && <Spinner animation={'border'} size={'sm'} style={{color: 'white'}}/>}
                        {!loading && <RefreshOutlined style={{color: 'white'}}/>}
                        <Overlay target={refreshLabelTarget.current} show={showRefreshLabel} placement="top">
                            {(props) => <Tooltip id="overlay-example" {...props}>Refresh Timesheet</Tooltip>}
                        </Overlay>
                    </div>
                    <div className='scheduler_actionBtn'
                         onClick={() => dispatch(handleDrawerChange(Drawer.AVAILABLE_CADDIES))}
                         ref={availCaddiesTarget}
                         onMouseEnter={() => setShowAvailCaddiesLabel(true)}
                         onMouseLeave={() => setShowAvailCaddiesLabel(false)}>
                        <GroupOutlined style={{color: 'white'}}/>
                        <Overlay target={availCaddiesTarget.current} show={showAvailCaddiesLabel} placement="top">
                            {(props) => <Tooltip id="overlay-example" {...props}>Your Caddies Panel</Tooltip>}
                        </Overlay>
                    </div>
                    <div className='scheduler_actionBtn'
                         onClick={showModal}
                         ref={busyDayTarget}
                         onMouseEnter={() => setShowBusyDayLabel(true)}
                         onMouseLeave={() => setShowBusyDayLabel(false)}>
                        <AddAlert style={{color: 'white'}}/>
                        <Overlay target={busyDayTarget.current} show={showBusyDayLabel} placement="top">
                            {(props) => <Tooltip id="overlay-example" {...props}>Send Busy Day Notification</Tooltip>}
                        </Overlay>
                    </div>
                    <div className='scheduler_publishBtn'
                         onClick={() => showPublishScheduleModal(true)}
                         ref={publishTarget}
                         style={{
                             backgroundColor: hasDraftBookings ? darkerHandicaddieGreen : '#707070',
                             cursor: hasDraftBookings ? 'pointer' : 'not-allowed'
                         }}
                         onMouseEnter={() => setShowPublishLabel(true)}
                         onMouseLeave={() => setShowPublishLabel(false)}>
                        <span>PUBLISH SCHEDULE</span>
                        <Overlay target={publishTarget.current} show={showPublishLabel} placement="top">
                            {(props) => <Tooltip id="overlay-example" {...props}>
                                {publishScheduleBtnLabel}
                            </Tooltip>}
                        </Overlay>
                    </div>
                </div>
            </div>
            {selectedClub.club && <BusyDayModal
                clubId={selectedClub.club.id}
                selectedDate={moment(selectedDate).format('YYYY-MM-DD')}
                showBusyDayModal={showBusyDayModal}
                handleBusyDayModalClose={handleBusyDayModalClose}
            />}
        </div>
    )
}

export default SchedulerHeader;