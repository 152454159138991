import React, {CSSProperties} from 'react';
import {CustomDropdown, Option} from "../CustomDropDown";
import {AppDispatch, RootState} from "../../../services/store/store";
import {useDispatch, useSelector} from "react-redux";
import {handleClubChange} from "../../../services/store/reducers/clubs/slice";

interface Props {
    classNames?: string
}

const ClubSelectorDropdown = ({classNames}: Props) => {

    const { allClubs, selectedClub } = useSelector((state: RootState) => state.clubs);
    const dispatch = useDispatch<AppDispatch>();

    return (
        <>
            {
                allClubs.length > 1 && selectedClub.club &&
                <CustomDropdown
                    options={allClubs.map<Option>(club => {
                        return {
                            value: club.id,
                            label: club.name,
                        };
                    })}
                    selectedValue={selectedClub.club.name}
                    onSelect={(value) => dispatch(handleClubChange(value))}
                    className={`border-b-dropdown ${classNames ? classNames : ""}`}/>
            }
        </>
    )
}

export default ClubSelectorDropdown;