import React, {useEffect, useState} from 'react';
import {AvailabilityStatus, UserAvailability} from "../../../../../services/ApiDomain";
import moment from "moment";
import CaddieAvailabilityCard from "../../../../../components/club/caddie_card/CaddieAvailabilityCard";
import {Image} from "react-bootstrap";
import {handicaddieSelector} from "../../../../../services/store/asyncThunk";
import CaddieSearchInput from '../components/CaddieSearchInput';
import CaddieUnavailabilityCard from "../../../../../components/club/caddie_card/CaddieUnavailabilityCard";
import {ArrowDropDown} from "@material-ui/icons";
import {CustomDropdown} from "../../../../../components/dropdown/CustomDropDown";

interface Props {

}

enum SortType {
    START_TIME = "Start Time",
    ALPHABETICAL = "A-Z"
}

const AvailableCaddiesDrawerContent = ({}: Props) => {


    const [startTimeSortedAvailableCaddies, setStartTimeSortedAvailableCaddies] = useState<UserAvailability[]>()
    const [alphabeticalAvailableCaddies, setAlphabeticalAvailableCaddies] = useState<UserAvailability[]>()

    const [sortType, setSortType] = useState<SortType>(SortType.START_TIME);

    const [maxBookingsCaddies, setMaxBookingsCaddies] = useState<UserAvailability[]>()
    const [maxBookingsOpen, setMaxBookingsOpen] = useState<boolean>(true)
    const [insufficientBalanceCaddies, setInsufficientBalanceCaddies] = useState<UserAvailability[]>()
    const [insufficientBalanceOpen, setInsufficientBalanceOpen] = useState<boolean>(true)
    const [unavailableCaddies, setUnavailableCaddies] = useState<UserAvailability[]>()
    const [unavailableCaddiesOpen, setUnavailableCaddiesOpen] = useState<boolean>(false)

    const { selectedDate, caddies, caddiesForDateLoading } = handicaddieSelector(state => state.scheduler)
    const { club } = handicaddieSelector(state => state.clubs.selectedClub)

    useEffect(() => {
        if (!caddies) {
            return;
        }

        let smartphoneCaddies = caddies.filter(c => c.noSmartphoneCaddie === false);

        setStartTimeSortedAvailableCaddies(smartphoneCaddies.filter(c => c.availabilityStatus === AvailabilityStatus.AVAILABLE));
        setAlphabeticalAvailableCaddies(smartphoneCaddies.filter(c => c.availabilityStatus === AvailabilityStatus.AVAILABLE)
            .sort((c1, c2) => c1.user.displayName.localeCompare(c2.user.displayName)));
        setMaxBookingsCaddies(smartphoneCaddies.filter(c => c.availabilityStatus === AvailabilityStatus.MAX_BOOKINGS));
        setInsufficientBalanceCaddies(smartphoneCaddies.filter(c => c.availabilityStatus === AvailabilityStatus.INSUFFICIENT_BALANCE));
        setUnavailableCaddies(smartphoneCaddies.filter(c => c.availabilityStatus === AvailabilityStatus.UNAVAILABLE));
    }, [caddies])


    const dragStart = (e: React.DragEvent, caddieId: string) => {
        e.dataTransfer.setData('caddieId', caddieId);
    }

    const dragOver = (e: React.DragEvent) => {
        e.stopPropagation();
    }

    const availableCaddies = sortType === SortType.START_TIME ? startTimeSortedAvailableCaddies : alphabeticalAvailableCaddies;

    return (
        <>
            <h3>Your Caddies</h3>
            <h6 style={{color: "grey"}}>{moment(selectedDate).format('dddd Do MMMM')}</h6>
            <CaddieSearchInput />
            <div id={"1"}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <p style={{marginTop: '32px', textTransform: 'uppercase', fontSize: '1.2em'}}>Available</p>
                    <div style={{ marginTop: '24px' }}>
                        <CustomDropdown
                            options={[
                                { label: SortType.START_TIME, value: SortType.START_TIME},
                                { label: SortType.ALPHABETICAL, value: SortType.ALPHABETICAL}
                            ]}
                            selectedValue={sortType}
                            onSelect={(value) => setSortType(value as SortType)}
                            className={"caddie_panel_sorting_dropdown"}
                        />
                    </div>
                </div>

                {availableCaddies && availableCaddies.map((c: UserAvailability) => {
                    return <div
                        draggable={!!c.caddieId && c.available}
                        onDragStart={(e) => {
                            if (!c.caddieId) {
                                return;
                            }
                            dragStart(e, c.caddieId)
                        }}
                        onDragOver={dragOver}>
                        {club && <CaddieAvailabilityCard date={selectedDate} clubId={club.id} userAvailability={c}/>}
                    </div>
                })}
            </div>
            {!caddiesForDateLoading && availableCaddies && availableCaddies.length === 0 && <>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    marginTop: '2em'
                }}>
                    <div>
                        <Image src={'/ohno.png'} style={{height: '15em'}} className="d-block mx-auto img-fluid"/>
                    </div>
                    <p style={{color: 'grey'}}>No caddies are available.</p>
                </div>
            </>}

            {maxBookingsCaddies && maxBookingsCaddies.length > 0 && <>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <p style={{marginTop: '32px', textTransform: 'uppercase', fontSize: '1.2em'}}>Fully Booked</p>
                    <div onClick={() => setMaxBookingsOpen(!maxBookingsOpen)}>
                        <ArrowDropDown style={{
                            rotate: maxBookingsOpen ? '0deg' : '180deg',
                            marginTop: '32px',
                            cursor: 'pointer',
                        }}/>
                    </div>
                </div>
                {maxBookingsOpen && maxBookingsCaddies.map((c: UserAvailability) => {
                    return <div draggable={false}>
                        <CaddieUnavailabilityCard userAvailability={c}/>
                    </div>
                })}
            </>}

            {insufficientBalanceCaddies && insufficientBalanceCaddies.length > 0 && <>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <p style={{marginTop: '32px', textTransform: 'uppercase', fontSize: '1.2em'}}>Insufficient Balance</p>
                    <div onClick={() => setInsufficientBalanceOpen(!insufficientBalanceOpen)}>
                        <ArrowDropDown style={{
                            rotate: insufficientBalanceOpen ? '0deg' : '180deg',
                            marginTop: '32px',
                            cursor: 'pointer',
                        }}/>
                    </div>
                </div>
                {insufficientBalanceOpen && insufficientBalanceCaddies.map((c: UserAvailability) => {
                    return <div draggable={false}>
                        <CaddieUnavailabilityCard userAvailability={c}/>
                    </div>
                })}
            </>}

            {unavailableCaddies && unavailableCaddies.length > 0 && <>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <p style={{marginTop: '32px', textTransform: 'uppercase', fontSize: '1.2em'}}>Unavailable</p>
                    <div onClick={() => setUnavailableCaddiesOpen(!unavailableCaddiesOpen)}>
                        <ArrowDropDown style={{
                            rotate: unavailableCaddiesOpen ? '0deg' : '180deg',
                            marginTop: '32px',
                            cursor: 'pointer',
                        }}/>
                    </div>
                </div>
                {unavailableCaddiesOpen && unavailableCaddies.map((c: UserAvailability) => {
                    return <div draggable={false}>
                        <CaddieUnavailabilityCard userAvailability={c}/>
                    </div>
                })}
            </>}
        </>
    )
}

export default AvailableCaddiesDrawerContent;