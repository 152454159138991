import React, {useEffect, useState} from 'react';
import {Alert, Button, Form, FormControl, InputGroup, Modal, Spinner} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../services/store/store";
import {useApi} from "../../../services/useApi";
import {toast} from "react-toastify";
import {handleClubUpdate} from "../../../services/store/reducers/clubs/slice";
import {getApiError} from "../../../services/Utils";
import {loadSelectedClubCourses} from "../../../services/store/reducers/clubs/asyncActions";

interface Props {
    showModal: boolean;
    closeModal: () => void;
    clubId: string;
    courseId?: string;
    initialInterval: number;
    initialFirstTeeTime: string;
    initialLastTeeTime: string;
    initialPaceOfPlay: number;
}

const TimesheetSettings = ({
                               showModal,
                               closeModal,
                               clubId,
                               courseId,
                               initialInterval,
                               initialFirstTeeTime,
                               initialLastTeeTime,
                               initialPaceOfPlay
                           }: Props) => {

    const {updateClubTimesheet, updateClubCourseTimesheet} = useApi();

    const [needsUpdating, setNeedsUpdating] = useState<boolean>(false)
    const [interval, setInterval] = useState<number>(initialInterval)
    const [firstTeeTime, setFirstTeeTime] = useState<string>(initialFirstTeeTime)
    const [lastTeeTime, setLastTeeTime] = useState<string>(initialLastTeeTime)
    const [paceOfPlay, setPaceOfPlay] = useState<number>(initialPaceOfPlay) // 6 hours

    const [updating, setUpdating] = useState<boolean>(false);
    const [errMsg, setErrMsg] = useState<string>();

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        setInterval(initialInterval)
        setFirstTeeTime(initialFirstTeeTime)
        setLastTeeTime(initialLastTeeTime)
        setPaceOfPlay(initialPaceOfPlay)
        setErrMsg(undefined)
        setNeedsUpdating(false)
    }, [clubId,
        courseId,
        initialInterval,
        initialFirstTeeTime,
        initialLastTeeTime,
        initialPaceOfPlay]);

    const updateValue = (setNewValue: () => void) => {
        setNewValue()
        setNeedsUpdating(true)
        setErrMsg(undefined)
    }

    const updateTimesheet = async () => {
        setUpdating(true);
        try {
            let settings = {
                timesheetInterval: interval,
                timesheetStartTime: firstTeeTime,
                timesheetEndTime: lastTeeTime,
                paceOfPlayMinutes: paceOfPlay
            }
            let r = await (courseId ?
                updateClubCourseTimesheet(clubId, courseId, settings) :
                updateClubTimesheet(clubId, settings))
            courseId ?
                dispatch(loadSelectedClubCourses()) :
                dispatch(handleClubUpdate(r))
            toast.success('Timesheet settings updated.')
            setNeedsUpdating(false)
            setErrMsg(undefined);
            closeModal()
        } catch (e) {
            let apiError = getApiError(e);
            setErrMsg(apiError ? apiError.message : 'An unknown problem occurred.');
        } finally {
            setUpdating(false);
        }
    }

    return (
        <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Update Timesheet Settings</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div>
                    <label htmlFor="basic-url" style={{marginBottom: 0}}>Interval</label>
                    <p style={{marginTop: '0em', marginBottom: '0.5em', fontSize: '0.75em', color: '#666'}}>
                        This is the number of minutes between each tee time.</p>
                    <InputGroup className="mb-3">
                        <FormControl
                            type="number"
                            placeholder="Interval"
                            value={interval}
                            onChange={(e) => updateValue(() => setInterval(parseInt(e.target.value)))}
                        />
                        <InputGroup.Append>
                            <InputGroup.Text>minutes</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>


                    <label htmlFor="basic-url" style={{marginBottom: 0}}>First Tee Time</label>
                    <p style={{marginTop: '0em', marginBottom: '0.5em', fontSize: '0.75em', color: '#666'}}>
                        Your first tee time on any given day.</p>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type="time"
                            placeholder="Time"
                            value={firstTeeTime}
                            onChange={(e) => updateValue(() => setFirstTeeTime(e.target.value))}
                        />
                    </InputGroup>

                    <label htmlFor="basic-url" style={{marginBottom: 0}}>Last Tee Time</label>
                    <p style={{marginTop: '0em', marginBottom: '0.5em', fontSize: '0.75em', color: '#666'}}>Your final tee time on any given day.</p>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type="time"
                            placeholder="Time"
                            value={lastTeeTime}
                            onChange={(e) => updateValue(() => setLastTeeTime(e.target.value))}
                        />
                    </InputGroup>
                </div>

                <label htmlFor="basic-url" style={{marginBottom: 0}}>Pace of Play</label>
                    <p style={{marginTop: '0em', marginBottom: '0.5em', fontSize: '0.75em', color: '#666'}}>This is how
                        long your caddie will be unavailable for once they are placed on a booking. This time should
                        equate to your pace of play for your course plus an additional rest period for your caddie.</p>
                <Form.Control as="select"
                              value={paceOfPlay}
                              onChange={(e) => updateValue(() => setPaceOfPlay(parseInt(e.target.value)))}>
                    <option value={240}>4 hours</option>
                    <option value={270}>4.5 hours</option>
                    <option value={300}>5 hours</option>
                    <option value={330}>5.5 hours</option>
                    <option value={360}>6 hours</option>
                    <option value={390}>6.5 hours</option>
                </Form.Control>

                <div style={{
                    marginTop: '1em',
                    borderTop: '1px solid #ccc',
                    paddingTop: '1em'
                }}>
                    {needsUpdating && <Alert variant='warning'>
                        You have changed your timesheet settings. We will attempt to update all your future bookings
                        to the new available tee times based on these new settings.
                        <p style={{padding: 0, margin: 0}}>If you are happy with your changes, please click update
                            below.</p>
                    </Alert>}

                    {errMsg && <Alert variant='danger'>{errMsg}</Alert>}

                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: '1em',
                        paddingBottom: '0.75em',
                    }}>
                        {updating && <Spinner animation={'border'}/>}
                        <Button
                            variant='outline-danger'
                            style={{marginRight: '0.5em'}}
                            disabled={updating}
                            onClick={closeModal}>Cancel</Button>
                        <Button disabled={updating} onClick={updateTimesheet}>UPDATE</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default TimesheetSettings;