import ApiClient from "../../ApiClient";
import {logErrorFromApi} from "../Utils";
import {User} from "../ApiDomain";
import {toast} from "react-toastify";

export interface Member {
    id: string
    externalRef: string
    clubId: string
    clubName: string
    claimed: boolean

    user: User
}

export interface MemberComment {
    id: string
    comment: string
    member: string

    submitted: User
    submittedOn: Date
}

export const MemberService = () => {

    const create = (clubId: string, firstName: string, lastName: string, email: string): Promise<Member | void> => {
        return ApiClient.getInstance().post<Member>(`/members`, {
            clubId: clubId,
            user: {
                firstName: firstName,
                lastName: lastName,
                email: email,
            }
        })
            .then((resp) => resp)
            .catch((err) => logErrorFromApi(err));
    }

    const find = (clubId: string, searchTerm?: string): Promise<Member[] | void> => {
        return ApiClient.getInstance().get<Member[]>(`/members?forClubId=${clubId}&forSearch=${searchTerm}`)
            .then((resp) => resp)
            .catch((err) => logErrorFromApi(err));
    }

    const get = (id: string): Promise<Member | void> => {
        return ApiClient.getInstance().get<Member>(`/members/${id}`)
            .then((resp) => resp)
            .catch((err) => logErrorFromApi(err));
    }

    const getComments = (id: string): Promise<MemberComment[] | void> => {
        return ApiClient.getInstance().get<MemberComment[]>(`/members/${id}/comments`)
            .then((resp) => resp)
            .catch((err) => logErrorFromApi(err));
    }

    const createComment = (id: string, comment: string): Promise<MemberComment | void> => {
        return ApiClient.getInstance().post<MemberComment>(`/members/${id}/comments`, {
            memberId: id,
            comment: comment
        })
            .then((resp) => resp)
            .catch((err) => logErrorFromApi(err));
    }

    const deleteComment = (id: string, commentId: string): Promise<boolean | void> => {
        return ApiClient.getInstance().delete<void>(`/members/${id}/comments/${commentId}`)
            .then(() => {
                toast.success('Comment deleted successfully.')
            })
            .catch((err) => logErrorFromApi(err));
    }

    return {
        createMember: create,
        getMember: get,
        findMembers: find,
        getMemberComments: getComments,
        createMemberComment: createComment,
        deleteMemberComment: deleteComment,
    }
}
