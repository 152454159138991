import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import ClubBookingForm from "./ClubBookingForm";
import {toast} from "react-toastify";
import {useApi} from "../../../../services/useApi";
import {calculateSlotsWithNewCount} from "../../../../services/store/reducers/scheduler/service";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../services/store/store";
import {addBooking} from "../../../../services/store/reducers/scheduler/asyncActions";
import {handicaddieSelector} from "../../../../services/store/asyncThunk";

type ClubBookingModalProps = {
    clubId: string
    modalTitle: string
    show: boolean
    refreshBookings: () => void
    handleClose: () => void
}

const ClubBookingModal = ({modalTitle, show, refreshBookings, handleClose}: ClubBookingModalProps) => {

    const { getBookingSlots } = useApi();

    const [title, setTitle] = useState<string>();
    const [minCaddies, setMinCaddies] = useState<number>(1);
    const [numberOfCaddies, setNumberOfCaddies] = useState<number>();
    const [caddieMasterNotes, setCaddieMasterNotes] = useState<string>();
    const [caddieNotes, setCaddieNotes] = useState<string>();

    const [actionsEnabled, setActionsEnabled] = useState<boolean>(true);

    const dispatch = useDispatch<AppDispatch>();
    const { selectedTeeTime } = handicaddieSelector(state => state.scheduler)

    useEffect(() => {
        if (!show) {
            return;
        }

        if (!selectedTeeTime) {
            toast.error('Unable to determine the booking you are editing.')
            return;
        }

        setTitle(selectedTeeTime.booking?.title)
        setCaddieMasterNotes(selectedTeeTime.booking?.caddieMasterNotes)
        setCaddieNotes(selectedTeeTime.booking?.caddieNotes)
        if (selectedTeeTime.booking) {
            setMinCaddies(selectedTeeTime.booking.bookingSlots.filter(slot => slot.caddieId != null).length)
            setNumberOfCaddies(selectedTeeTime.booking.bookingSlots.length)
        } else {
            setMinCaddies(1)
            setNumberOfCaddies(4)
        }
        setActionsEnabled(true);
    }, [selectedTeeTime, show])

    const updateBooking = async () => {
        if (!numberOfCaddies) {
            toast.error('Unable to determine the number of caddies selected.')
            return;
        }

        if (!selectedTeeTime?.booking || !selectedTeeTime.teeTime) {
            toast.error('Unable to determine the booking you are editing.')
            return;
        }

        let id = selectedTeeTime.booking.bookingId

        let existingSlots = await getBookingSlots(id);
        let resp = calculateSlotsWithNewCount(existingSlots, numberOfCaddies)
        if (resp.error) {
            toast.warn('Booking was updated, but number of caddies was not.')
            return;
        }
        let slots = resp.slots.map(slot => {
            slot.bookingId = id
            return slot
        });

        dispatch(addBooking({
            bookingId: id,
            bookingSlots: slots,
            caddieMasterNotes: caddieMasterNotes,
            caddieNotes: caddieNotes,
            title: title,
            teeTime: selectedTeeTime.teeTime,
        }))
        handleClose()
        refreshBookings()
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ClubBookingForm
                    title={title}
                    updateTitle={setTitle}
                    numberOfCaddies={numberOfCaddies}
                    updateNumberOfCaddies={setNumberOfCaddies}
                    minCaddies={minCaddies}
                    caddieMasterNotes={caddieMasterNotes}
                    updateCaddieMasterNotes={setCaddieMasterNotes}
                    caddieNotes={caddieNotes}
                    updateCaddieNotes={setCaddieNotes}
                    submit={updateBooking}
                    actionsEnabled={actionsEnabled}
                    closeBookingModal={handleClose}  />
            </Modal.Body>
        </Modal>
    )
}

export default ClubBookingModal;