import {Col, Container, Row, Spinner} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import PlatformNavBar from "../components/navbar/PlatformNavBar";
import {Route, Routes, useNavigate} from "react-router-dom";
import {
    ADD_MEMBER,
    AVAILABILITY,
    BOOKINGS,
    CADDIE_ON_TOUR,
    CADDIE_PROFILE,
    CADDIE_PROGRAM_REPORT,
    CADDIE_WEB_NOTICE,
    CADDIES,
    EDIT_MY_PROFILE,
    LET_CADDIE_APPLICATION,
    MEMBER_PROFILE,
    MEMBERS,
    MEMBERSHIPS,
    MY_PROFILE,
    PAYMENT_SUCCESSFUL,
    PAYMENT_UNSUCCESSFUL,
    SCHEDULER,
    SETTINGS,
    TOUR_MASTER_BILLING,
    TOUR_MASTER_CADDIES,
    TOUR_MASTER_COMMUNICATION,
    TOUR_MASTER_DOCUMENTS,
    TOUR_PASSES,
    USER_PROFILE
} from "../services/PageUtils";
import Memberships from "../pages/Memberships";
import MyBookings from "../pages/MyBookings";
import AnnouncementBanner from "../components/announcement/AnnouncementBanner";
import WelcomeAnnouncement from "../components/announcement/welcome/WelcomeAnnouncement";
import CaddieProfile from "../pages/CaddieProfile";
import Availability from "../pages/Availability";
import Caddies from "../pages/Caddies";
import Scheduler from "../pages/Scheduler";
import {MyProfile} from "../pages/MyProfile";
import {EditMyProfile} from "../pages/EditMyProfile";
import {useStore} from "../services/state/useStore";
import CaddieOnTour from "../pages/CaddieOnTour";
import LadiesEuropeanTourCaddieApplication from "../pages/LadiesEuropeanTourCaddieApplication";
import {TourMasterCaddies} from "../pages/TourMasterCaddies";
import {TourMasterBilling} from "../pages/TourMasterBilling";
import TourPasses from "../pages/TourPasses";
import PaymentSuccessful from "../pages/PaymentSuccessful";
import PaymentUnsuccessful from "../pages/PaymentUnsuccessful";
import {TourMasterCommunication} from "../pages/TourMasterCommunication";
import {TourMasterDocuments} from "../pages/TourMasterDocuments";
import UserProfile from "../pages/UserProfile";
import {Dashboard} from "../pages/Dashboard";
import {CaddieProgramReport} from "../pages/CaddieProgramReport";
import DraftModeAutoPublishBanner from "../components/draftmodeautopublishbanner/DraftModeAutoPublishBanner";
import {AutoPublishStatus, TimesheetService} from "../services/timesheet/TimesheetService";
import {Settings} from "../pages/Settings";
import {RootState} from "../services/store/store";
import {useSelector} from "react-redux";
import Members from "../pages/Members";
import MemberProfile from "../pages/MemberProfile";
import AddNewMember from "../pages/AddNewMember";

interface Props {
    logOut: () => void
}

const PlatformApp = ({logOut}: Props) => {

    const navigate = useNavigate();

    const { nextTimesheetAutoPublish } = TimesheetService();
    const [autoPublishStatus, setAutoPublishStatus] = useState<AutoPublishStatus | undefined>();

    let autoPublishCheckLoop = useRef<any>(null);

    const {
        userLoaded,
        caddiesLoaded,
        showAnnouncementBanner,
        caddieMastersLoaded,
        caddieMasters,
        tourMasters,
        tourMastersLoaded,
    } = useStore();
    const { selectedClub } = useSelector((state: RootState) => state.clubs)

    if (tourMastersLoaded && tourMasters.length === 0 &&
        caddieMastersLoaded && caddieMasters.length === 0) {
        navigate(CADDIE_WEB_NOTICE)
    }

    const HOUR_MS = 3600000;

    useEffect(() => {
        if (!selectedClub.club) {
            return;
        }

        if (autoPublishCheckLoop.current !== null) {
            clearInterval(autoPublishCheckLoop.current)
        }

        nextTimesheetAutoPublish(selectedClub.club.id)
            ?.then(r => {
                if (r) {
                    setAutoPublishStatus(r)
                }
            })

        autoPublishCheckLoop.current = setInterval( () => {
            nextTimesheetAutoPublish(selectedClub.club?.id)
                ?.then(r => {
                    if (r) {
                        setAutoPublishStatus(r)
                    }
                })
        }, HOUR_MS);
    }, [selectedClub])

    return (
        <>
            <PlatformNavBar logOut={logOut} isLocked={false} />
            {autoPublishStatus && autoPublishStatus.numberOfBookings > 0 && <DraftModeAutoPublishBanner status={autoPublishStatus}/>}
            <AnnouncementBanner>
                <WelcomeAnnouncement />
            </AnnouncementBanner>

            {(!userLoaded || !caddiesLoaded || !caddieMastersLoaded) &&
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '3em',
                    textAlign: 'center'
                }}>
                    <Row>
                    <Col>
                        <Spinner animation="border" variant="primary" />
                        <h5>Loading...</h5>
                    </Col>
                    </Row>
                </div>
            }

            <div style={{paddingTop: showAnnouncementBanner ? '0px' : '78px', height: "100%"}}>
            {(userLoaded && caddiesLoaded && caddieMastersLoaded) &&
            <Routes>
                <Route path={MEMBERSHIPS} element={<Container className={"contentContainer"} fluid="lg"> <Memberships/> </Container>} />
                <Route path={TOUR_MASTER_DOCUMENTS} element={<TourMasterDocuments />} />
                <Route path={TOUR_MASTER_COMMUNICATION} element={<TourMasterCommunication />} />
                <Route path={TOUR_MASTER_CADDIES} element={<TourMasterCaddies />} />
                <Route path={TOUR_MASTER_BILLING} element={<TourMasterBilling />} />
                <Route path={LET_CADDIE_APPLICATION} element={<Container className={"contentContainer"} fluid="lg"> <LadiesEuropeanTourCaddieApplication /> </Container>} />
                <Route path={TOUR_PASSES} element={<TourPasses />} />
                <Route path={PAYMENT_SUCCESSFUL} element={<PaymentSuccessful />} />
                <Route path={PAYMENT_UNSUCCESSFUL} element={<PaymentUnsuccessful />} />
                <Route path={CADDIE_ON_TOUR} element={<Container className={"contentContainer"} fluid="lg"> <CaddieOnTour /> </Container>} />
                <Route path={BOOKINGS} element={<Container className={"contentContainer"} fluid="lg"> <MyBookings/> </Container>} />
                <Route path={AVAILABILITY} element={<Container className={"contentContainer"} fluid="lg"> <Availability/> </Container>} />
                <Route path={CADDIES} element={<Container className={"contentContainer"} fluid="lg"> <Caddies/> </Container>} />
                <Route path={MEMBERS} element={<Container className={"contentContainer"} fluid="lg"> <Members/> </Container>} />
                <Route path={MEMBER_PROFILE} element={<MemberProfile/>}  />
                <Route path={ADD_MEMBER} element={<AddNewMember />} />
                <Route path={EDIT_MY_PROFILE} element={<Container className={"contentContainer"} fluid="lg"> <EditMyProfile/> </Container>} />
                <Route path={MY_PROFILE} element={<MyProfile />} />
                <Route path={CADDIE_PROFILE} element={<CaddieProfile />} />
                <Route path={USER_PROFILE} element={<UserProfile />} />
                <Route path={CADDIE_PROGRAM_REPORT} element={<CaddieProgramReport />} />
                <Route path={SCHEDULER} element={<Scheduler />} />
                <Route path={SETTINGS} element={<Settings />} />
                <Route index element={<Dashboard />} />
            </Routes>
            }
            </div>
        </>
    )

}

export default PlatformApp;