import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../services/store/store";
import {Alert, Button, Form, FormControl, InputGroup, Spinner} from "react-bootstrap";
import {useApi} from "../../../services/useApi";
import {handleClubUpdate} from "../../../services/store/reducers/clubs/slice";
import {toast} from "react-toastify";
import {getApiError} from "../../../services/Utils";

interface Props {

}

const ClubSettingsPage = ({}: Props) => {

    const { isHandicaddieUser } = useSelector((state: RootState) => state.currentUser);
    const { club } = useSelector((state: RootState) => state.clubs.selectedClub);

    const { updateClub } = useApi();

    const dispatch = useDispatch<AppDispatch>()

    const [updating, setUpdating] = useState<boolean>(false)
    const [needsUpdating, setNeedsUpdating] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState<string|undefined>()

    const [name, setName] = useState<string>()
    const [timesheet, setTimesheet] = useState<boolean>(true)
    const [players, setPlayers] = useState<boolean>(false)
    const [members, setMembers] = useState<boolean>(false)

    useEffect(() => {
        reset()
    }, [club]);

    const updateValue = (setNewValue: () => void) => {
        setNewValue()
        setNeedsUpdating(true)
        setErrMsg(undefined)
    }

    const reset = () => {
        setName(club ? club.name : undefined)
        setTimesheet(club ? club.hasTimesheet : true)
        setPlayers(club ? club.hasPlayers : false)
        setMembers(club ? club.hasMembers : false)
        setNeedsUpdating(false)
    }

    const saveChanges = async () => {
        if (!club) {
            return;
        }

        try {
            setUpdating(true)
            if (!name || name.length === 0) {
                toast.error('Please enter a club name.')
                return;
            }
            let payload = {
                ...club,
                name: name,
                hasTimesheet: timesheet,
                hasPlayers: players,
                hasMembers: members,
            }
            let r = await updateClub(club.id, payload)
            dispatch(handleClubUpdate(r))
            toast.success('Changes applied.')
        } catch (e: any) {
            let apiError = getApiError(e);
            toast.error(apiError.message ? apiError.message : 'Unknown error occurred applying updates.')
        } finally {
            setUpdating(false)
            setNeedsUpdating(false)
        }
    }

    if (!club) {
        return <>
            <Spinner animation='border' />
        </>
    }

    return (
        <>
            <div className="pageSubHeading">
                CLUB SETTINGS
            </div>
            <p style={{color: '#707070'}}>
                In this section you can update your overall club settings
            </p>

            <label htmlFor="basic-url" style={{marginBottom: 0}}>Name</label>
            <p style={{marginTop: '0em', marginBottom: '0.5em', fontSize: '0.75em', color: '#666'}}>
                The name that appears in communications related to your club and the name that appears on the app for
                caddies.</p>
            <InputGroup className="mb-3">
                <FormControl
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => updateValue(() => setName(e.target.value))}
                />
            </InputGroup>

            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <label htmlFor="basic-url" style={{marginBottom: 0}}>Enable Timesheet</label>
                <Form.Check
                    type="checkbox"
                    checked={timesheet}
                    onChange={(e) => updateValue(() => setTimesheet(e.target.checked))}/>
            </div>
            <p style={{marginTop: '0em', marginBottom: '0.5em', fontSize: '0.75em', color: '#666'}}>
                Activate this setting if your club operates on specific tee times.
                Once enabled, you can configure your timesheet preferences in the Timesheet Settings section.
            </p>

            {isHandicaddieUser && <>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <label htmlFor="basic-url" style={{marginBottom: 0}}>Enable Players</label>
                    <Form.Check
                        type="checkbox"
                        checked={players}
                        onChange={(e) => updateValue(() => setPlayers(e.target.checked))}/>
                </div>
                <p style={{marginTop: '0em', marginBottom: '0.5em', fontSize: '0.75em', color: '#666'}}>
                    Turn on this setting to add player names to individual slots in the scheduler.
                </p>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <label htmlFor="basic-url" style={{marginBottom: 0}}>Enable Members</label>
                    <Form.Check
                        type="checkbox"
                        checked={members}
                        onChange={(e) => updateValue(() => setMembers(e.target.checked))}/>
                </div>
                <p style={{marginTop: '0em', marginBottom: '0.5em', fontSize: '0.75em', color: '#666'}}>
                    Enable this setting to manage member profiles and track their rounds, perfect for clubs with a membership system.
                </p>
            </>}

            <div style={{
                borderTop: '1px solid #ccc',
                paddingTop: '1em'
            }}>
                {needsUpdating && <Alert variant='warning'>
                    <p style={{padding: 0, margin: 0}}>If you are happy with the changes you have made, please click
                        update below.</p>
                </Alert>}

                {errMsg && <Alert variant='danger'>{errMsg}</Alert>}

                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '1em',
                    paddingBottom: '0.75em',
                }}>
                    {updating && <Spinner animation={'border'}/>}
                    <Button
                        variant='outline-danger'
                        style={{marginRight: '0.5em'}}
                        disabled={updating}
                        onClick={reset}>
                        Reset
                    </Button>
                    <Button disabled={updating} onClick={saveChanges}>UPDATE</Button>
                </div>
            </div>
        </>
    )
}

export default ClubSettingsPage;