import React from 'react';
import BookingDrawerContent from "./content/BookingDrawerContent";
import AvailableCaddiesDrawerContent from "./content/AvailableCaddiesDrawerContent";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";
import {darkerHandicaddieGreen} from "../../../../services/Utils";
import {Drawer, DrawerStatus} from "../../../../services/store/reducers/scheduler/domain";
import {handicaddieSelector} from "../../../../services/store/asyncThunk";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../services/store/store";
import {handleToggleDrawer} from "../../../../services/store/reducers/scheduler/slice";

interface Props {
}

const SideDrawer = ({}: Props) => {

    const {
        activeDrawer,
        drawerStatus } = handicaddieSelector(state => state.scheduler)
    const dispatch = useDispatch<AppDispatch>();

    const toggleDrawer = () => {
        dispatch(handleToggleDrawer())
    }

    return (
        <div style={{
            width: drawerStatus === DrawerStatus.OPEN ? `${100 - drawerStatus}%` : '0',
            minWidth: '4em',
            margin: 0,
            backgroundColor: 'white',
            position: 'fixed',
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflow: drawerStatus === DrawerStatus.OPEN ? "scroll" : "hidden",
            overscrollBehavior: 'none',
            zIndex: 500
        }}>
            <div style={{
                padding: '1em',
                paddingBottom: '10em',
            }}>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '1em',
                    paddingBottom: '0.75em',
                    borderBottom: '1px solid #ccc'
                }}>
                    <div style={{
                        height: '2em',
                        width: '2em',
                        backgroundColor: darkerHandicaddieGreen,
                        textAlign: 'center',
                        cursor: 'pointer'
                    }} onClick={toggleDrawer}>
                        {drawerStatus === DrawerStatus.OPEN && <ChevronRight style={{ color: 'white', verticalAlign: 'text-top' }} />}
                        {drawerStatus === DrawerStatus.CLOSED && <ChevronLeft style={{ color: 'white', verticalAlign: 'text-top' }} />}
                    </div>
                </div>
                {drawerStatus === DrawerStatus.OPEN && activeDrawer === Drawer.AVAILABLE_CADDIES &&
                    <AvailableCaddiesDrawerContent />}
                {drawerStatus === DrawerStatus.OPEN && activeDrawer === Drawer.BOOKING &&
                    <BookingDrawerContent />}
            </div>
        </div>
    )
}

export default SideDrawer;