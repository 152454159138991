import React from 'react';
import {Badge, Card, Col, Row} from "react-bootstrap";
import moment from "moment";
import Avatar from "../../Avatar";
import {UserAvailability} from "../../../services/ApiDomain";

type CaddieCardProps = {
    userAvailability: UserAvailability,
};

const CaddieUnavailabilityCard = ({userAvailability}: CaddieCardProps) => {

    let userId = userAvailability.user.id
    let name = userAvailability.user.displayName

    return (
        <div style={{ width: "100%", padding: '8px', borderTop: '1px solid #8b8b8b', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div>
                <Avatar user={userId} size={'xs'}/>
            </div>
            <div>
                <span style={{ color: '#8b8b8b' }}>{name}</span>
            </div>
        </div>
    )
}

export default CaddieUnavailabilityCard;