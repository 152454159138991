import {BookingSlot, ClubBookingSlot} from "../../../ApiDomain";

export enum Drawer {
    AVAILABLE_CADDIES,
    BOOKING
}

export enum DrawerStatus {
    OPEN = 70,
    CLOSED = 95
}

export interface CreateBookingForm {
    teeTime: string
    bookingId?: string
    title?: string
    caddieMasterNotes?: string
    caddieNotes?: string
    courseId?: string
    endTeeTime?: string
    bookingSlots?: BookingSlot[]
}

export interface SlotCalculationErrorResponse {
    slots: ClubBookingSlot[]
    error?: "TOO_MANY_NON_EMPTY_SLOTS"
}

export interface DeleteModalAction {
    show: boolean
    bookingId?: string
}
