import React from 'react';
import {Badge, Card, Col, Row} from "react-bootstrap";
import moment from "moment";
import Avatar from "../../Avatar";
import {UserAvailability} from "../../../services/ApiDomain";

type CaddieCardProps = {
    clubId: string
    date: string
    userAvailability: UserAvailability,
    onClick?: () => void,
    children?: React.ReactNode
};

const CaddieAvailabilityCard = ({clubId, date, userAvailability, onClick = () => {}, children}: CaddieCardProps) => {

    let userId = userAvailability.user.id
    let name = userAvailability.user.displayName
    let phoneNumber = userAvailability.user.phoneNumber

    let window = ''
    let doubleBag = false
    let dateAvailabilities = userAvailability.availabilities.filter((a) => {
        // wow, lol! -> https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
        // let tStr = moment(date).format('YYYY-MM-DD')
        return a.date === moment(date).format('YYYY-MM-DD') && a.club?.id && a.club.id === clubId
    });

    if (dateAvailabilities.length === 1) {
        let availabilityResource = dateAvailabilities[0];
        doubleBag = !!availabilityResource.doubleBag

        if (availabilityResource.windows && availabilityResource.windows.length === 1) {
            let windowResource = availabilityResource.windows[0];
            let from = windowResource.from === '00:00' ? 'Start of Day' : windowResource.from
            let to = windowResource.to === '23:59' ? 'End of Day' : windowResource.to
            window = from + ' - ' + to
        }
    }

    return (
        <Card onClick={() => onClick()} className="caddieCard" style={{ cursor: 'grab' }}>
            <Card.Body>
                <Row>
                    <Col xs={12} md={"auto"}>
                        <Avatar user={userId} size={'smd'} />
                    </Col>
                    <Col>
                        <h4>{name}</h4>
                        {!userAvailability.available && <Badge variant={"danger"}>Not available</Badge>}
                        {userAvailability.available && <>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         style={{marginTop: '0.2em', marginRight: '0.5em'}}>
                                        <path
                                            d="M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 7.99999C14.6663 4.3181 11.6816 1.33333 7.99967 1.33333C4.31778 1.33333 1.33301 4.3181 1.33301 7.99999C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z"
                                            stroke="#707070" stroke-width="1.25"/>
                                        <path d="M8 4.66667V8.00001L9.66667 9.66667" stroke="#707070"
                                              stroke-width="1.25"/>
                                    </svg>
                                    <p style={{marginBottom: 0}}>{window ? window : 'All day'}</p>
                                </>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                {doubleBag &&
                                    <>
                                        <svg width="16" height="8" viewBox="0 0 16 8" fill="none" style={{ marginTop: '0.5em', marginRight: '0.5em' }}>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M0.378906 3.63652C0.378906 1.83733 1.83744 0.378799 3.63663 0.378799C5.43583 0.378799 6.89435 1.83733 6.89435 3.63652C6.89435 5.43572 5.43583 6.89424 3.63663 6.89424C1.83744 6.89424 0.378906 5.43572 0.378906 3.63652Z"
                                                  fill="#707070"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M9.10547 3.63652C9.10547 1.83733 10.564 0.378799 12.3632 0.378799C14.1624 0.378799 15.6209 1.83733 15.6209 3.63652C15.6209 5.43572 14.1624 6.89424 12.3632 6.89424C10.564 6.89424 9.10547 5.43572 9.10547 3.63652Z"
                                                  fill="#707070"/>
                                        </svg>
                                        <p style={{marginBottom: 0}}>Multiple bags</p>
                                    </>}
                                {!doubleBag &&
                                    <>
                                        <svg width="16" height="8" viewBox="0 0 16 8" fill="none" style={{ marginTop: '0.5em', marginRight: '0.5em' }}>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M0.378906 3.63652C0.378906 1.83733 1.83744 0.378799 3.63663 0.378799C5.43583 0.378799 6.89435 1.83733 6.89435 3.63652C6.89435 5.43572 5.43583 6.89424 3.63663 6.89424C1.83744 6.89424 0.378906 5.43572 0.378906 3.63652Z"
                                                  fill="#707070"/>
                                            <path
                                                d="M9.60547 3.63652C9.60547 2.11347 10.8401 0.878799 12.3632 0.878799C13.8862 0.878799 15.1209 2.11347 15.1209 3.63652C15.1209 5.15958 13.8862 6.39424 12.3632 6.39424C10.8401 6.39424 9.60547 5.15958 9.60547 3.63652Z"
                                                stroke="#707070"/>
                                        </svg>
                                        <p style={{marginBottom: 0}}>Single bag only</p>
                                    </>}
                            </div>
                        </>}
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            {phoneNumber && <>
                                <svg viewBox="0 0 24 24" width="16" height="16" color="#707070" fill="none"  style={{ marginTop: '0.2em', marginRight: '0.5em' }}>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M9.8198 9.25C8.98561 9.25 8.26739 9.8388 8.10379 10.6568L7.83991 11.9762L3.86204 13.7843C2.88031 14.2306 2.25 15.2094 2.25 16.2878V21C2.25 21.9665 3.0335 22.75 4 22.75H20C20.9665 22.75 21.75 21.9665 21.75 21V16.2878C21.75 15.2094 21.1197 14.2306 20.138 13.7843L16.1601 11.9762L15.8962 10.6568C15.7326 9.83881 15.0144 9.25 14.1802 9.25H9.8198ZM12 14.25C10.4812 14.25 9.25 15.4812 9.25 17C9.25 18.5188 10.4812 19.75 12 19.75C13.5188 19.75 14.75 18.5188 14.75 17C14.75 15.4812 13.5188 14.25 12 14.25Z"
                                          fill="currentColor"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M8 4.3918V6.6648C8 7.89629 7.24741 9.00269 6.10202 9.45506L2.36734 10.9301C2.05943 11.0517 1.7113 11.013 1.43757 10.8268C1.16384 10.6406 1 10.3311 1 10V8.04372C1 6.45647 1.76031 4.8674 3.26123 4.05096C4.20076 3.5399 5.44402 2.95939 6.75348 2.6263C10.0363 1.79123 13.9637 1.79123 17.2465 2.6263C18.556 2.95939 19.7992 3.5399 20.7388 4.05096C22.2397 4.8674 23 6.45647 23 8.04372V10C23 10.3311 22.8362 10.6406 22.5624 10.8268C22.2887 11.013 21.9406 11.0517 21.6327 10.9301L17.898 9.45506C16.7526 9.00269 16 7.89629 16 6.6648V4.3918C13.4546 3.8694 10.5454 3.8694 8 4.3918Z"
                                          fill="currentColor"/>
                                </svg>
                                <p style={{marginBottom: 0}}><i>{phoneNumber}</i></p>
                            </>}
                        </div>

                    </Col>
                </Row>
            </Card.Body>
            {children && children}
        </Card>
    )
}

export default CaddieAvailabilityCard;