import React, {useState} from 'react';
import {BookingSlot} from "../../../services/ApiDomain";
import MobileDateScheduler from "./mobile/MobileDateScheduler";
import MobileSchedulerHeader from "./mobile/MobileSchedulerHeader";
import CaddieSelectionModal from "./caddie_selector_model/CaddieSelectionModal";
import ClubBookingModal from "./booking_modal/ClubBookingModal";
import DeleteBookingModal from "./booking_modal/DeleteBookingModal";
import {handicaddieSelector} from "../../../services/store/asyncThunk";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../services/store/store";
import {handleDateChange, handleShowDeleteBookingModal} from "../../../services/store/reducers/scheduler/slice";
import {reloadScheduler} from "../../../services/store/reducers/scheduler/asyncActions";

const MobileCaddieMasterScheduler = (props: { }) => {

    const {
        selectedTeeTime,
        selectedDate,
        showDeleteModal } = handicaddieSelector(state => state.scheduler)
    const { club } = handicaddieSelector(state => state.clubs.selectedClub)
    const dispatch = useDispatch<AppDispatch>();

    const [showCaddieSelectionModal, setShowCaddieSelectionModal] = useState<boolean>(false);
    const [selectedCaddieId, setSelectedCaddieId] = useState<string>();
    const [selectedBookingSlot, setSelectedBookingSlot] = useState<BookingSlot>();

    const [showEditBookingModal, setShowEditBookingModal] = useState<boolean>(false);

    const handleCaddieSelectionClose = () => {
        dispatch(reloadScheduler())
        setShowCaddieSelectionModal(false);
    }

    const handleCaddieSelectionShow = (bookingSlot: BookingSlot, date: string) => {
        setSelectedCaddieId(bookingSlot?.caddieId);
        setSelectedBookingSlot(bookingSlot)
        dispatch(handleDateChange(date))
        setShowCaddieSelectionModal(true);
    }

    const handleEditBookingClose = () => setShowEditBookingModal(false);

    const handleEditBookingShow = () => {
        setShowEditBookingModal(true);
    }

    return <div style={{width: '100%'}}>
        <MobileSchedulerHeader />
        <MobileDateScheduler
            displayCaddieSelector={handleCaddieSelectionShow}
            displayEditBookingModal={handleEditBookingShow}
            displayDeleteBookingModal={(id) => dispatch(handleShowDeleteBookingModal({
                bookingId: id,
                show: true}))
            }
        />

        {club && <CaddieSelectionModal
            clubId={club.id}
            caddieId={selectedCaddieId}
            bookingSlot={selectedBookingSlot}
            booking={selectedTeeTime?.booking}
            date={selectedDate}
            showCaddieSelectionModal={showCaddieSelectionModal}
            handleCaddieSelectionClose={handleCaddieSelectionClose} />}

        {club && <ClubBookingModal
            clubId={club?.id}
            modalTitle="Edit Booking"
            show={showEditBookingModal}
            refreshBookings={() => dispatch(reloadScheduler())}
            handleClose={handleEditBookingClose} />}

        <DeleteBookingModal
            showDeleteBookingModal={showDeleteModal}
            handleDeleteBookingModalClose={() => dispatch(handleShowDeleteBookingModal({
                show: false
            }))}
        />
    </div>;
}

export default MobileCaddieMasterScheduler;